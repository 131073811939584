
.flex-set {
    display: -ms-flexbox;
    /* MID: IE 10 */
    display: -webkit-box;
    /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -webkit-flex;
    /* NEW, Chrome 21–28, Safari 6.1+ */
    display: flex;
}

.flexed-item {
    flex: 1 1 0;
    -webkit-flex: 1 1 0;
    /* VERY OLD Safari, iOS  */
    -webkit-box-flex: 1;
    box-flex: 1;
}

.flexed-item--auto {
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    /* VERY OLD Safari, iOS  */
    -webkit-box-flex: 1;
    box-flex: 1;
}

.flex--column {
    -ms-flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.flex--row {
    -ms-flex-direction: row;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.flex--content-center {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.flex--content-start {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

.flex--content-end {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.flex--content-between {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.flex--content-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.flex--align-content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
}

.flex--align-content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
}

.flex--align-content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
}

.flex--align-content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
}

.flex--align-content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
}

.flex--align-content-stretch {
    -ms-flex-line-pack: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
}

.flex--align-items-center {
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

.flex--align-items-start {
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -webkit-box-align: start;
    align-items: flex-start;
}

.flex--align-items-end {
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -webkit-box-align: end;
    align-items: flex-end;
}

.flex--align-items-baseline {
    -ms-flex-align: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;
    align-items: baseline;
}

.flex--align-items-stretch {
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
}

.flex--align-self-auto {
    -ms-flex-item-align: auto;
    -webkit-align-self: auto;
    align-self: auto;
}

.flex--align-self-start {
    -ms-flex-item-align: start;
    -webkit-align-self: flex-start;
    align-self: flex-start;
}

.flex--align-self-end {
    -ms-flex-item-align: end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
}

.flex--align-self-center {
    -ms-flex-item-align: center;
    -webkit-align-self: center;
    align-self: center;
}

.flex--align-self-baseline {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
}

.flex--align-self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

.flex--no-wrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.flex--reverse-wrap {
    -webkit-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
}

.flex--wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex--order-one {
    -webkit-order: 1;
    order: 1;
}

.flex--order-two {
    -webkit-order: 2;
    order: 2;
}

.flex--order-three {
    -webkit-order: 3;
    order: 3;
}
